// @flow strict
import React from "react";
import { content, content__body, content__title } from "./Content.module.scss";

type Props = {
  body: string,
  title: string,
};

const Content = ({ body, title }: Props) => (
  <div className={content}>
    <h1 className={content__title}>{title}</h1>
    <div className={content__body} dangerouslySetInnerHTML={{ __html: body }} />
  </div>
);

export default Content;
