// @flow strict
import { Link } from "gatsby";
import React from "react";
import {
  tags as tagsStyle,
  tags__list,
  tags__list_item,
  tags__list_item_link,
} from "./Tags.module.scss";

type Props = {
  tags: string[],
  tagSlugs: string[],
};

const Tags = ({ tags, tagSlugs }: Props) => (
  <div className={tagsStyle}>
    <ul className={tags__list}>
      {tagSlugs &&
        tagSlugs.map((slug, i) => (
          <li className={tags__list_item} key={tags[i]}>
            <Link to={slug} className={tags__list_item_link}>
              {tags[i]}
            </Link>
          </li>
        ))}
    </ul>
  </div>
);

export default Tags;
