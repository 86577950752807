// @flow strict
import React from "react";
import { useSiteMetadata } from "../../../hooks";
import { getContactHref } from "../../../utils";
import {
  author as authorStyle,
  author__bio,
  author__bio_twitter,
} from "./Author.module.scss";

const Author = () => {
  const { author } = useSiteMetadata();

  return (
    <div className={authorStyle}>
      <p className={author__bio}>
        {author.bio}
        <a
          className={author__bio_twitter}
          href={getContactHref("twitter", author.contacts.twitter)}
          rel="noopener noreferrer"
          target="_blank"
        >
          <strong>{author.name}</strong> on Twitter
        </a>
      </p>
    </div>
  );
};

export default Author;
