// @flow strict
import { Link } from "gatsby";
import React from "react";
import type { Node } from "../../types";
import Author from "./Author";
import Content from "./Content";
import Meta from "./Meta";
import {
  post as postStyle,
  post__comments,
  post__content,
  post__footer,
  post__home_button,
} from "./Post.module.scss";
import Tags from "./Tags";

type Props = {
  post: Node,
};

const Post = ({ post }: Props) => {
  const { html } = post;
  const { tagSlugs, slug } = post.fields;
  const { tags, title, date } = post.frontmatter;

  return (
    <div className={postStyle}>
      <Link className={post__home_button} to="/">
        All Articles
      </Link>

      <div className={post__content}>
        <Content body={html} title={title} />
      </div>

      <div className={post__footer}>
        <Meta date={date} />
        {tags && tagSlugs && <Tags tags={tags} tagSlugs={tagSlugs} />}
        <Author />
      </div>
    </div>
  );
};

export default Post;
